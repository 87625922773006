@media print {
  .noprint {
    display: none;
  }
}

* {
  padding: 0;
}

h1.black-bg {
  background-color: black;
  color: white;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-container h3 {
  width: 100%;
  text-align: left;
  color: #888;
  font-size: 16px;
  font-weight: bold;
}

h3 {
  font-weight: bold;
  text-align: left;
  color: black;
  /*font-size: 16px;*/
}

.right-align {
  text-align: right;
}

.left-align {
  text-align: left;
}

h3 {
  font-weight: bold;
  font-weight: 600;
  /*color: grey;*/
  color: black;
  /*font-size: 16px;*/
}

td {
  font-size: 14px !important;
}
